module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/favicon.svg","name":"tBTC","shortName":"tBTC","description":"tBTC — Bitcoin Everywhere","start_url":"/","display":"standalone","lang":"en","localize":[{"start_url":"/de/","lang":"de","name":"tBTC","short_name":"tBTC","description":"tBTC — Bitcoin überall."}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"925b79eae29c5dff65698cb4c72ac619"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/home/runner/work/tbtc-website/tbtc-website/src/cms/cms.js"},
    }]
